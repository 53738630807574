<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card card-plain">
          <tabs centered square>
            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('common.details') }}</div>
                </div>
              </template>
              <octo-header-button :title="course.title" :buttons="certificateButtons" @onSaveCourse="saveCourse"/>
              <card body-classes="standard-card-body">
                <template slot="header">
                  <h4 class="card-title text-capitalize mb-0"> {{ $t('course.certificate_data') }}</h4>
                </template>
                <certificate-form-card :course="course" :key="`form-${courseKey}`" ref="certificateForm"/>
              </card>
            </tab-pane>
            <tab-pane>
              <template slot="label">
                <div class="d-flex align-items-center">
                  <div class="text-uppercase m-3">{{ $t('common.courses') }}</div>
                </div>
              </template>
              <octo-header-button :title="course.title" :buttons="coursesButtons" @onSyncCourses="syncCourses"/>
              <certificate-courses-card
                :course="course"
                :key="`certificates-${courseKey}`"
                ref="certificateCoursesCard"
              />
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {mapMutations} from "vuex";
import {TabPane, Tabs} from '@/components';
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import Course from "@/models/course";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import CertificateCoursesCard from "./components/CertificateCoursesCard";
import CertificateFormCard from "./components/CertificateFormCard";

export default {
  name: "ShowCertificatePage",
  components: {
    CertificateFormCard,
    CertificateCoursesCard,
    OctoIcon,
    OctoHeaderButton,
    TabPane,
    Tabs
  },
  data() {
    return {
      endpoints: endpoints,
      course: this.$_.cloneDeep(Course),
      courseKey: 1,
      certificateButtons: [
        {
          label: 'save',
          onClick: 'onSaveCourse',
        }
      ],
      coursesButtons: [
        {
          label: 'save_courses',
          onClick: 'onSyncCourses',
        }
      ],
    }
  },
  beforeMount() {
    this.$fullLoading.show();
    this.$api.get(endpoints.COURSE_SHOW.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.course = resp.data.data;
        this.courseKey++;
        this.$fullLoading.hide();
      })
      .catch(() => {
        this.$fullLoading.hide();
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$router.push({name: 'courses.certificates.index'})
      })
  },
  methods: {
    ...mapMutations({
      updateCourses: 'common/updateCourses'
    }),

    saveCourse: async function () {
      try {
        this.$fullLoading.show();

        const courseData = await this.$refs.certificateForm.validate();

        const resp = await this.$api.put(
          endpoints.COURSE_UPDATE.replace('{id}', courseData.id),
          courseData
        );

        this.updateCourses(resp?.data?.data);

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    syncCourses: async function () {
      try {
        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.COURSE_SYNC_COURSES.replace('{id}', this.course.id),
          {courseIds: this.$refs.certificateCoursesCard.getSelectedCourseIds()}
        );

        this.updateCourses(resp?.data?.data);

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },
  }
}
</script>

<style scoped>

</style>
